import React from 'react';

function Modal({isOpen, onClose, children, classNameModal}) {
    if (!isOpen) {
        return null;
    } else {
        return (
            <div class="modal__background--style position-fixed d-flex justify-content-center align-items-center z-3">
                <div className={`modal__background--color rounded position-fixed overflow-scroll ${classNameModal}`}>
                    <div>
                        {children}
                    </div>
                    <div className="d-flex justify-content-end align-items-end position-absolute bottom-0 end-0">
                        <button onClick={onClose} className="rounded bg-black text-light p-1 ps-2 pe-2 m-3">Fermer</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default Modal;