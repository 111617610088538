import React from 'react';

// FILE
import Avis from './Avis.js';

// IMAGE
import Agnes from "../../assets/imgAvis/agnes.jpg";
import Youcom from "../../assets/imgAvis/youcom.jpg";
import Morgan from "../../assets/imgAvis/morgan.jpg";

// AVIS
const avisAgnes = 
    `Je tenais à te féliciter pour les magnifiques photos du mariage de Benjamin et Zaineb. Nous avons été sous le charme de chacune d'elles. 
    <br/>C'est une véritable réussite qui nous laisse des souvenirs exceptionnels de cette journée si importante pour nous. 
    <br/>Tu as été au cœur de notre bonheur et je suis si heureuse de t'avoir fais confiance. Tu as des qualités professionnelles 
    et humaines irréprochables et nos demandes ont toutes été comblées.`;
const avisYoucom = "Elles sont canoooons 😍 moi j'aime beaucoup ! Elles sont vraiment chouettes ! Merci beaucoup 🙏";
const avisMorgan = "Je kiff de ouf ! Même sur mes yeux t'as fait grave un taf de ouf. Limite je me trouve beau Haha. Hâte de voir la suite.";

function SectionAvis() {
    return (
        <div className="container">
            <h2 className="row justify-content-center m-5 fs-secondary">Avis</h2>
            <div className="row">
                <div className="col-1"></div>
                <Avis firstName="Agnès" img={Agnes} avis={avisAgnes}/>
                <div className="d-none d-lg-block col-7"></div>
                <div className="d-lg-none col-1"></div>
                <div className="d-none d-lg-block col-7"></div>
                <div className="d-lg-none col-1"></div>
                <Avis firstName="Youcom" img={Youcom} avis={avisYoucom} />
                <div className="col-1"></div>
                <div className="col-1"></div>
                <Avis firstName="Morgan" img={Morgan} avis={avisMorgan} />
                <div className="d-none d-lg-block col-7"></div>
                <div className="d-lg-none col-1"></div>
            </div>
        </div>
    )
}

export default SectionAvis;
