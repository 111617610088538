import React from 'react';

// FILE
import Caroussel from '../components/Caroussel.js';
import Description from '../components/Description.js';
import Prestation from '../components/Prestations/Prestation.js';
import SectionAvis from '../components/avis/SectionAvis.js';
import Contact from '../components/Contact.js';

// CSS
import '../assets/css/Home.css';

function Home() {
    return (
        <main>
            <h1 className="visually-hidden">Marie Populaire Photographie</h1>
            <Caroussel />
            <Description />
            <Prestation />
            <SectionAvis />
            <Contact />
        </main>
    )
}

export default Home;