import { React, useEffect, useState } from 'react';
import Modal from "../components/Modal.js";
import axios from 'axios';

function Inscription() {

    // GET TOKEN 
    const urlToken = "http://www.mariepopulairephotographie.fr/backend/tokenCsrf.php";
    const [csrfToken, setCsrfToken] = useState('');
    useEffect(() => {
        async function getToken() {
            const response = await axios.get(urlToken);
            setCsrfToken(response.data.csrfToken);
            }
    
        getToken();
    }, []);

    // HANDLER FORM INSCRIPTION
    const [inscriptionData, setInscriptionData] = useState({
        token : csrfToken,
        lastname : '',
        firstname : '',
        phone : '',
        address : '',
        email : '',
        pwd : '',
        role : 'user',
    })

    // EVENT LISTENER INPUT CHANGE 
    const inputChange = (e) => { 
        const {name, value} = e.target; // e.target.name -> nom de mon input | e.target.value -> valeur de mon input
        setInscriptionData(prevState => ({
            ...prevState, // conserver les autres valeurs de inscriptionData
            [name]: value, // nom de input mod = modification en cours
        }));
    };

    // HANDLER FORM SEND
    const [responseMessage, setResponseMessage] = useState(null);
    const url = "http://www.mariepopulairephotographie.fr/backend/inscription.php"; // cible de traitement ou localhost pour développement
    const sendFormInscription = async (e) => {
        e.preventDefault(); // empêcher le rafraîchissement de la page
        try { // envoi des informations d'inscription
            const response = await axios.post(url, JSON.stringify(inscriptionData), {
                headers: {
                    'Content-Type': 'application/json' // format JSON
                }
            })
            setResponseMessage(response.data);
            setModalState(true);
        } catch { // capture erreur de transfert
            const errorConnexionServeur = (
                <p>Oops, une erreur s'est produite, tu n'as pas été enregistré !</p>
            )
            setResponseMessage(errorConnexionServeur);
            setModalState(true);
        };
    }

    // HANDLER SHOW MODAL
    const [modalState, setModalState] = useState(false);

    const closeModal = () => {
        setModalState(false);
    }

    // HANDLER FEEDBACK INSCRIPTION (SUCCESS/FAIL)
    function renderMessage(response) {
        if (response && response.success) { // inscription effectuée
            // REINITIALIZE INPUT AFTER VALIDATION
                setInscriptionData({
                    lastname : '',
                    firstname : '',
                    phone : '',
                    address : '',
                    email : '',
                    pwd : '',
                });
            return (
                <div>
                    <p>Inscription réussie, plus qu'à passer aux prestations !</p>
                </div>
            )
        } else {
            return (
                <div>
                    <p>{response} est déjà utilisé </p>
                </div>
            )
        }
    }


    // FORMULAIRE CONNEXION
    return (
        <div className="row d-flex justify-content-center mt-5 pt-5 mb-5 pb-5 m-0">
            <div className="col-1 col-sm-2 col-md-3 col-lg-3 col-xl-4 col-xxl-4"></div>
            <form className="col-10 col-sm-8 col-md-6 col-lg-6 col-xl-4 col-xxl-4 card p-3 p-xs-4 p-md-5" onSubmit={sendFormInscription}>
                <legend className="text-center">Inscrivez-vous !</legend>
                <input type="hidden" name="csrfToken" value={inscriptionData.token}/>
                <div className="d-flex flex-column m-1">
                    <label className="" htmlFor="firstname" >Prénom : </label>
                    <input className="rounded ps-1" id="firstname" name="firstname" value={inscriptionData.firstname} type="text" onChange={inputChange} required></input>
                </div>
                <div className="d-flex flex-column m-1">
                    <label className="" htmlFor="lastname" >Nom : </label>
                    <input className="rounded ps-1" id="lastname" name="lastname" value={inscriptionData.lastname} type="text" onChange={inputChange} required></input>
                </div>
                <div className="d-flex flex-column m-1">
                    <label className="" htmlFor="phone" >Téléphone : </label>
                    <input className="rounded ps-1" id="phone" name="phone" value={inscriptionData.phone} type="tel" onChange={inputChange} required></input>
                </div>
                <div className="d-flex flex-column m-1">
                    <label className="" htmlFor="address" >Adresse : </label>
                    <input className="rounded ps-1" id="address" name="address" value={inscriptionData.address} type="text" onChange={inputChange} required></input>
                </div>
                <div className="d-flex flex-column m-1">
                    <label className="" htmlFor="email" >Email : </label>
                    <input className="rounded ps-1" id="email" name="email" value={inscriptionData.email} type="email" onChange={inputChange} required></input>
                </div>
                <div className="d-flex flex-column m-1">
                    <label className="" htmlFor="pwd" >Mot de passe : </label>
                    <input className="rounded ps-1" name="pwd" id="pwd" value={inscriptionData.pwd} type="password" onChange={inputChange} required></input>
                </div>
                <div className="d-flex justify-content-center m-4 pt-3">
                    <button className="btn-CTA" type="submit">Envoyer</button>
                </div>
            </form>
            <div className="col-1 col-sm-2 col-md-3 col-lg-3 col-xl-4 col-xxl-4"></div>
            <Modal isOpen={modalState} onClose={closeModal} classNameModal="modal__responseForm--style">
                <div className="p-3 p-sm-5" id="response">{renderMessage(responseMessage)}</div>
            </Modal>
        </div>
    )
};

export default Inscription;