import React, { useState, useEffect } from 'react';
import axios from 'axios';

// FILE
import Modal from "./Modal.js";


function Form({object, readonly}) {

    // GET TOKEN 
    const urlToken = "http://www.mariepopulairephotographie.fr/backend/tokenCsrf.php";
    const [csrfToken, setCsrfToken] = useState('');
    useEffect(() => {
        async function getToken() {
            const response = await axios.get(urlToken);
            setCsrfToken(response.data.csrfToken);
            }
    
        getToken();
    }, []);

    //  SEND FORM via axios
    const [FormData, setFormData] = useState({
        token : csrfToken,
        lastname : '',
        firstname : '',
        mail : '',
        telephone : '',
        object : object || '',
        message : '',
    })

    const inputChange = (e) => {
        const {name, value} = e.target; // e.target.name -> nom de mon input | e.target.value -> valeur de mon input
        setFormData(lastState => ({
            ...lastState, // conserver les autres valeurs de formData
            [name]: value, // nom de input mod = modification en cours
        }));
    };


    // ENVOI
    const [responseMessage, setResponseMessage] = useState(null);
    const url = "http://localhost:3000" //http://www.mariepopulairephotographie.fr/backend/send_mail.php"; // cible de traitement ou localhost pour développement
    const sendForm = async (e) => {
        e.preventDefault(); // empêcher le rafraichissement de la page
        try { // envoi l'email
            const response = await axios.post(url, JSON.stringify(FormData), {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            setResponseMessage(response.data);
            setModalState(true);
        } catch { // capturer l'erreur lors de l'envoi de l'email
            const errorMessage = (
                <div>
                    <p>Oops! Il y a eu un petit problème... Je n'ai pas pu envoyer votre message.</p>
                    <p>En attendant, contactez moi sur <a href="https://www.instagram.com/mariepopulaire.photographie?igsh=ZTR0ZWlocTlicjlz">Instagram</a> ou sur mon numéro (06 33 68 03 49).</p>
                    <div className="row">
                        <div className="col-8"></div>
                        <h5 className="col-2">Marie</h5>
                    </div>
                </div>
            )
            setResponseMessage(errorMessage);
            setModalState(true);
        }
    }

    // GESTION DE L'AFFICHAGE DE LA MODAL
    const [modalState, setModalState] = useState(false);

    const closeModal = () => {
        setModalState(false);
    }

    // GESTION DE L'AFFICHAGE DE LA REPONSE D'ENVOI
    function renderMessage(response) {
        if (response && response.success) { // Envoi validé
            return (
                <div>
                    <p>{response.message}</p>
                    <p>Je vous réponds très bientôt !</p>
                    <div className="row">
                        <div className="col-8"></div>
                        <h5 className="col-2">Marie</h5>
                    </div>
                </div>
            );
        } else { // Echec de l'envoi - erreur de connexion avec le backend
            return (
                <div>
                    <p>Oops! Il y a eu un petit problème... Je n'ai pas pu envoyer votre message.</p>
                        <p>En attendant, contactez moi sur <a href="https://www.instagram.com/mariepopulaire.photographie?igsh=ZTR0ZWlocTlicjlz">Instagram</a> ou sur mon numéro (06 33 68 03 49).</p>
                        <div className="row">
                            <div className="col-8"></div>
                            <h5 className="col-2">Marie</h5>
                        </div>
                </div>
            );
        }
    } 


    // FORM
    return (
            <div>
                <p className="mb-2 p-3 fs-3 text-center">Un projet ? Écrivez-moi !</p>
                <form className="d-flex flex-column justify-content-center" onSubmit={sendForm}>
                    <fieldset>
                    <input type="hidden" name="csrfToken" value={FormData.token}/>
                        {/* 1st LINE */}
                        <div className="row m-0 mt-1 mb-1">
                            <div className="col-1"></div>
                            <div className="col-10 col-sm-4 d-flex flex-column p-0">
                                <label htmlFor="lastname">Nom</label>
                                <input className="rounded ps-1" name="lastname" id="lastname" onChange={inputChange} required></input>
                            </div>
                            <div className="col-1"></div>
                            <div className="col-1"></div>
                            <div className="col-10 col-sm-4 d-flex flex-column p-0">
                                <label htmlFor="firstname">Prénom</label>
                                <input className="rounded ps-1" name="firstname" id="firstname" value={FormData.firstname} onChange={inputChange} required></input>
                            </div>
                            <div className="col-1"></div>
                        </div>
                        {/* 2nd LINE */}
                        <div className="row m-0 mt-1 mb-1">
                            <div className="col-1"></div>
                            <div className="col-10 col-sm-4 d-flex flex-column p-0">
                                <label htmlFor="mail">Mail</label>
                                <input className="rounded ps-1" name="mail" id="mail" type="email" value={FormData.mail} onChange={inputChange} required></input>
                            </div>
                            <div className="col-1"></div>
                            <div className="col-1"></div>
                            <div className="col-10 col-sm-4 d-flex flex-column p-0">
                                <label htmlFor="telephone">Téléphone</label>
                                <input className="rounded ps-1" name="telephone" id="telephone" value={FormData.telephone} onChange={inputChange} required></input>
                            </div>
                            <div className="col-1"></div>
                        </div>
                    </fieldset>
                    <fieldset>
                        {/* 3rd LINE */}
                        <div className="row m-0 mt-1 mb-1">
                            <div className="col-1 col-sm-2"></div>
                            <div className="col-10 col-sm-8 d-flex flex-column pt-3 p-0 p-sm-0">
                                <label htmlFor="objet">Objet</label>
                                <input className="rounded ps-1" name="object" id="object" {...readonly} value={FormData.object} onChange={inputChange} required></input>
                            </div>
                            <div className="col-2 p-0"></div>
                        </div>
                        {/* 4th LINE */}
                        <div className="row m-0 mt-1 mb-1">
                            <div className="col-1 col-sm-2"></div>
                            <div className="col-10 col-sm-8 d-flex flex-column p-0">
                                <label htmlFor="message">Message</label>
                                <textarea className="rounded ps-1" name="message" id="message" maxLength="500" rows="7" value={FormData.message} onChange={inputChange} required></textarea>
                            </div>
                            <div className="col-2"></div>
                        </div>
                        <div className="row m-0 mt-1 mb-1">
                            <div className="col-12">
                                <div className="d-flex justify-content-center m-4">
                                    <button className="btn-CTA" type="submit">Envoyer</button>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
                <Modal isOpen={modalState} onClose={closeModal} classNameModal="modal__responseForm--style">
                    <div className="p-3 p-sm-5" id="response">{renderMessage(responseMessage)}</div>
                </Modal>
            </div>
    )
}

export default Form;