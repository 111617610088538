import { React, useState } from 'react';

// FILE
import Contact from '../components/Contact.js';
import Modal from "../components/Modal.js";
import Form from "../components/Form.js";

// IMAGE
import imgAnimaux from '../assets/imgPrestation/animaux.webp';
import clic from '../assets/imgPrestation/animaux/Clic.webp';
import bestiole from '../assets/imgPrestation/animaux/Bestiole.webp';


const Animaux = () => {
    const [modalState, setModalState] = useState({ isOpen: false, offer: null });

    const openModal = (offer) => {
        setModalState({isOpen: true, offer});
    }
    
    const closeModal = () => {
        setModalState({isOpen: false, offer: null});
    }

    return (
        <div className="d-flex align-items-center flex-column">
            <Contact />
            <h2 className="m-5 m-sm-3 fs-secondary">Animaux</h2>
            <img className="w-75 m-sm-3 content__prestation--width img__presentation--shadow" src={imgAnimaux} alt="prestation Animaux" loading="lazy"></img>
            <div className="fs-6 p-5 p-sm-3 text-center content__prestation--width">
                <p className="mb-sm-5 mt-sm-5">
                Capturez la <strong>personnalité</strong> et la <strong>beauté</strong> de votre fidèle compagnon avec mes 
                séances shootings animaliers. Que ce soit à la <strong>maison</strong> ou en <strong>pleine nature</strong>, 
                immortalisez les moments magiques passés <strong>ensemble</strong>, pour ne <strong>jamais oublier</strong>.
                </p>
            </div>
            <div className="container d-flex flex-column align-items-center">
                <h3 className="row mt-3 mb-3 mt-sm-5 fs-1">Mes formules</h3>
                <div className="row d-flex justify-content-center m-sm-5 ms-sm-5 me-sm-5 offer__prestation--width">
                    <div className="col-12 col-sm-6 mb-3 p-sm-4">
                        <div className="carte p-4 m-4 p-sm-0 m-sm-0">
                            <div className="mt-3 m-sm-3 d-flex justify-content-center">
                                <img className="img-fluid w-50" src={clic} alt="animaux clics moustachus" loading="lazy"></img>
                            </div>
                            <h3 className="m-4 m-sm-5 text-center">Clics moustachus</h3>
                            <ul className="ps-5 pe-2 p-sm-0">
                                <li className="pb-3 text-start">Session de prise de vue de 30' pour capturer des moments spontanés et adorables avec votre animal de compagnie</li>
                                <li className="pb-3 text-start">Choix de lieu en extérieur ou à domicile pour un environnement familier</li>
                                <li className="pb-3 text-start">Sélection des 10 meilleures photos prises pendant la session</li>
                                <li className="pb-3 text-start">Galerie en ligne privée pour visionner et télécharger les photos</li>
                                <li className="pb-3 text-start">120 €</li>
                            </ul>
                            <div className="d-flex justify-content-center">
                                <button onClick={() => openModal("Animaux - Clics moustachus")} className="btn-CTA m-1">Je réserve !</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 mb-3 p-sm-4">
                        <div className="carte p-4 m-4 p-sm-0 m-sm-0">
                            <div className="mt-3 m-sm-3 d-flex justify-content-center">
                                <img className="img-fluid w-50" src={bestiole} alt="animaux bestioles en beauté" loading="lazy"></img>
                            </div>
                            <h3 className="m-4 m-sm-5 text-center">Bestioles en beauté</h3>
                            <ul className="ps-5 pe-2 p-sm-0">
                                <li className="pb-3 text-start">Session de prise de vue de 60' pour capturer la personnalité unique de votre animal de compagnie</li>
                                <li className="pb-3 text-start">Choix de lieu en extérieur ou à domicile pour un environnement familier</li>
                                <li className="pb-3 text-start">Mise en valeur des expressions et des comportements naturels de votre animal</li>
                                <li className="pb-3 text-start">Possibilité de poser avec votre animal</li>
                                <li className="pb-3 text-start">Sélection et retouche des 20 meilleures photos</li>
                                <li className="pb-3 text-start">Galerie en ligne privée pour visionner et télécharger les photos</li>
                                <li className="pb-3 text-start">230 €</li>
                            </ul>
                            <div className="d-flex justify-content-center">
                                <button onClick={() => openModal("Animaux - Bestioles en beauté")} className="btn-CTA m-1">Je réserve !</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={modalState.isOpen} onClose={closeModal}>
                    <Form object={modalState.offer} readonly={{ readOnly: true }}/>
                </Modal>
            </div>
        </div>
    );
}

export default Animaux;
