import React from 'react';
import ContactSvg from "../assets/icons/contact.svg";
import { useState } from 'react';
import Modal from "./Modal.js";
import Form from "./Form.js";

function Contact() {
    const [isOpenModal, setIsOpenModal] = useState(false);

    const openModal = () => setIsOpenModal(true);
    const closeModal = () => setIsOpenModal(false);

    return (
        <div className="row m-0">
            <div className="col position-fixed d-flex justify-content-center contact z-2">
                <button onClick={openModal} className="onClick" > 
                    <img className="backgroundContact p-1 p-sm-2 pt-0 pb-0" src={ContactSvg} alt="contact par mail"></img>
                </button> 
            </div>
            <Modal isOpen={isOpenModal} onClose={closeModal}>
                <Form />
            </Modal>
        </div>
    )
}

export default Contact;