import React from 'react';

// FILE
import TermsOfSales from './TermsOfSales.js';
import LegalNotice from './LegalNotice.js';
import Form from "../Form.js";

// ICON
import FacebookIcon from '../../assets/icons/facebook.svg';
import InstaIcon from '../../assets/icons/instagram.svg';

// CSS
import './Footer.css';


const Footer = () => {
    const instagramLink = 'https://www.instagram.com/mariepopulaire.photographie?igsh=ZTR0ZWlocTlicjlz';
    const facebookLink = 'https://www.facebook.com/profile.php?id=100086897901856';

    return (
        <footer className="mt-5 p-2 pt-5">
{/* FOOTER */}
    {/* FORM */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-6" id="footerForm">
                        <Form />
                    </div>
    {/* COORDONNEES */}
                    <div className="col-lg-4">
                        <h2 className="mt-5 m-2 m-sm-2 text-center text-sm-start">Marie Populaire Photographie</h2>
                        <div className="col-lg-12 d-flex justify-content-around mt-4">
                            <div className="row m-2 p-2">
                                <a target="_blank" rel="noopener noreferrer" href={instagramLink}><img src={InstaIcon} alt="instagram icon"></img></a>
                            </div>
                            <div className="row m-2 p-2">
                                <a target="_blank" rel="noopener noreferrer" href={facebookLink}><img src={FacebookIcon} alt="facebook icon"></img></a>
                            </div>
                        </div>
                        <div className="p-lg-3 mb-5">
                            <p className="fs-5">Mes coordonnées</p>
                            <p className="ps-3 fs-xs">mariepopulaire.photographie@laposte.net</p>
                            <p className="ps-3 fs-xs"><a className="text-decoration-none" href="tel:0633680349">06 33 68 03 49</a></p>
                            <p className="ps-3 fs-xs">numéro de siren : 907-566-533 </p>
                        </div>
                    </div> 
                </div>

    {/* LEGAL NOTICE */}
                <div className="row text-center">
                    <p className="m-0 p-0">© 2024 Marie Populaire Photographie. Tous droits réservés.</p>
                </div>
                <div className="row">
                    <div className="col text-center d-flex justify-content-center">
                            <p className="text-decoration-underline text-primary footer__link--style" data-bs-toggle="modal" data-bs-target="#TermsOfSales">CGV </p> 
                            &nbsp;-&nbsp; 
                            <p className="text-decoration-underline text-primary footer__link--style" data-bs-toggle="modal" data-bs-target="#LegalNotice"> Mentions Légales</p>
                    <TermsOfSales />
                    <LegalNotice />
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
