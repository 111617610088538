import { React, useState } from 'react';


// FILE
import Contact from '../components/Contact.js';
import Modal from "../components/Modal.js";
import Form from "../components/Form.js";

// IMAGE
import imgProfessionnel from '../assets/imgPrestation/professionnel.webp';
import portrait from '../assets/imgPrestation/professionnel/Portrait.webp';
import equipe from '../assets/imgPrestation/professionnel/Equipe.webp';
import reportage from '../assets/imgPrestation/professionnel/Reportage.webp';


const Professionnel = () => {
    const [modalState, setModalState] = useState({ isOpen: false, offer: null });

    const openModal = (offer) => {
        setModalState({isOpen: true, offer});
    }
    
    const closeModal = () => {
        setModalState({isOpen: false, offer: null});
    }

    return (
        <div className="d-flex align-items-center flex-column">
            <Contact />
            <h2 className="m-5 m-sm-3 fs-secondary">Professionnel</h2>
            <img className=" w-75 m-sm-3 content__prestation--width img__presentation--shadow" src={imgProfessionnel} alt="prestation Professionnel" loading="lazy"></img>
            <div className="fs-6 p-5 p-sm-3 text-center content__prestation--width">
                <p className="mb-sm-5 mt-sm-5">
                Présentez votre entreprise sous son <strong>meilleur jour</strong>. 
                Créez une image <strong>professionnelle</strong> et cohérente qui reflète l'essence même de 
                votre marque, que ce soit à travers des <strong>portraits individuels</strong>, des <strong>photos 
                d'équipe</strong> ou la couverture d'<strong>événements</strong> corporatifs.<br />
                <br />
                Mes propositions sont conçues pour répondre à vos besoins. Pour une <strong>offre sur mesure</strong> qui correspond à vos objectifs d'entreprise, n'hésitez pas à me contacter pour élaborer un <strong>devis personnalisé</strong>.
                </p>
            </div>
            <div className="container d-flex flex-column align-items-center">
                <h3 className="row mt-3 mb-3 mt-sm-5 fs-1">Mes formules</h3>
                <div className="row d-flex justify-content-center m-sm-5 ms-sm-5 me-sm-5 offer__prestation--width">
                    <div className="col-12 col-sm-6 mb-3 p-sm-4">
                        <div className="carte p-4 m-4 p-sm-0 m-sm-0">
                            <div className="mt-3 m-sm-3 d-flex justify-content-center">
                                <img className="img-fluid imgLandscape__prestation--width" src={portrait} alt="professionnel portrait" loading="lazy"></img>
                            </div>
                            <h3 className="m-4 m-sm-5 text-center">Portrait professionnel</h3>
                            <ul className="ps-5 pe-2 p-sm-0">
                                <li className="pb-3 text-start">Séance de photographie individuelle pour chaque employé</li>
                                <li className="pb-3 text-start">Capture de portraits professionnels de haute qualité mettant en valeur l'image et la personnalité de chaque membre de l'équipe</li>
                                <li className="pb-3 text-start">Sélection et retouche des meilleures photos pour assurer une apparence professionnelle et soignée</li>
                                <li className="pb-3 text-start">Galerie en ligne privée pour visionner et télécharger les photos individuelles</li>
                                <li className="pb-3 text-start">Demande de devis obligatoire</li>
                            </ul>
                            <div className="d-flex justify-content-center">
                                <button onClick={() => openModal("Professionnel - Portrait professionnel")} className="btn-CTA m-1">Je réserve !</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 mb-3 p-sm-4">
                        <div className="carte p-4 m-4 p-sm-0 m-sm-0">
                            <div className="mt-3 m-sm-3 d-flex justify-content-center">
                                <img className="img-fluid imgLandscape__prestation--width" src={equipe} alt="professionnel equipe" loading="lazy"></img>
                            </div>
                            <h3 className="m-4 m-sm-5 text-center">Équipe dynamique</h3>
                            <ul className="ps-5 pe-2 p-sm-0">
                                <li className="pb-3 text-start">Séance de photographie de groupe pour capturer l'esprit d'équipe et la dynamique de l'entreprise</li>
                                <li className="pb-3 text-start">Prise de vue dans un environnement professionnel ou dans un lieu choisi pour refléter la culture et les valeurs de l'entreprise</li>
                                <li className="pb-3 text-start">Mise en scène des membres de l'équipe dans des poses naturelles et engageantes</li>
                                <li className="pb-3 text-start">Sélection et retouche des meilleures photos de groupe pour créer une image cohésive de l'entreprise</li>
                                <li className="pb-3 text-start">Galerie en ligne privée pour visionner et télécharger les photos</li>
                                <li className="pb-3 text-start">Demande de devis obligatoire</li>
                            </ul>
                            <div className="d-flex justify-content-center">
                                <button onClick={() => openModal("Professionnel - Équipe dynamique")} className="btn-CTA m-1">Je réserve !</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 mb-3 p-sm-4">
                        <div className="carte p-4 m-4 p-sm-0 m-sm-0">
                            <div className="mt-3 m-sm-3 d-flex justify-content-center">
                                <img className="img-fluid imgLandscape__prestation--width" src={reportage} alt="professionnel reportage" loading="lazy"></img>
                            </div>
                            <h3 className="m-4 m-sm-5 text-center">Reportage d'événement</h3>
                            <ul className="ps-5 pe-2 p-sm-0">
                                <li className="pb-3 text-start">Couverture photographique complète d'un événement d'entreprise (concerts, conférences, séminaires, soirées de gala...)</li>
                                <li className="pb-3 text-start">Utilisation d'un mélange de photographies posées et d'images spontanées pour documenter l'événement de manière complète et authentique</li>
                                <li className="pb-3 text-start">Sélection et retouche des meilleures photos</li>
                                <li className="pb-3 text-start">Galerie en ligne privée pour visionner et télécharger les photos</li>
                                <li className="pb-3 text-start">Demande de devis obligatoire</li>
                            </ul>
                            <div className="d-flex justify-content-center">
                                <button onClick={() => openModal("Professionnel - Reportage d'événements")} className="btn-CTA m-1">Je réserve !</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={modalState.isOpen} onClose={closeModal}>
                    <Form object={modalState.offer} readonly={{ readOnly: true }}/>
                </Modal>
            </div>
        </div>
    );
}

export default Professionnel;
