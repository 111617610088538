import React from 'react';

function Lifestyle({avis, img, firstName}) {
    return (
        <div className="d-flex flex-column justify-content-start text-start text-sm-center mb-5 p-3 col-10 col-lg-4">
            <p dangerouslySetInnerHTML={{ __html: avis }}></p>
            <h4>{firstName}</h4>
            <div className="d-flex justify-content-end position-relative">
                <div className="avis__vignette--size d-flex justify-content-center align-items-center overflow-hidden position-absolute">
                    <img className="avis__img--size rounded-circle avis__border--set" src={img} alt={`portrait de ${firstName}`} loading="lazy"></img>
                </div>
            </div>
        </div>
    )
}

export default Lifestyle;