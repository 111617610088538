import React, { useState, useEffect } from 'react';
import imgCover1 from '../assets/imgCover/caroussel-1.webp';
import imgCover2 from '../assets/imgCover/caroussel-2.webp';
import imgCover3 from '../assets/imgCover/caroussel-3.webp';
import imgCover4 from '../assets/imgCover/caroussel-4.webp';
import imgCover5 from '../assets/imgCover/caroussel-5.webp';
import '../assets/css/Caroussel.css';

function Caroussel() {
    const [index, setIndex] = useState(0);
    const [loaded, setLoaded] = useState(false);
    
    const slides = [
        imgCover1,
        imgCover2,
        imgCover3,
        imgCover4,
        imgCover5,
    ]

    function switchCover() {
        setLoaded(false);
        setIndex((lastIndex) => (lastIndex + 1) % slides.length)
    }

    useEffect(() => {
        const interval = setInterval(switchCover, 4000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div className="d-flex justify-content-center">
            <div className="sizeView overflow-hidden">
                <img 
                    className={`carousel-image ${loaded ? 'carousel-image-loaded' : ''} object-fit-cover img-fluid`} 
                    src={slides[index]} alt={`slide ${index}`} onLoad={() => setLoaded(true)}>
                </img>
            </div>
        </div>
    )
}

export default Caroussel;