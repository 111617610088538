import { React, useState } from 'react';


// FILE
import Contact from '../components/Contact.js';
import Modal from "../components/Modal.js";
import Form from "../components/Form.js";

// IMAGE
import imgMariage from '../assets/imgPrestation/mariage.webp';
import lovebirds from '../assets/imgPrestation/mariage/Lovebirds.webp';
import fairytale from '../assets/imgPrestation/mariage/Farytale.webp';
import infinity from '../assets/imgPrestation/mariage/Infinity.webp';

const Mariage = () => {
    const [modalState, setModalState] = useState({ isOpen: false, offer: null });

    const openModal = (offer) => {
        setModalState({isOpen: true, offer});
    }
    
    const closeModal = () => {
        setModalState({isOpen: false, offer: null});
    }

    return (
        <div className="d-flex align-items-center flex-column">
            <Contact />
            <h2 className="m-5 m-sm-3 fs-secondary">Mariage</h2> 
            <img className="w-75 m-sm-3 content__prestation--width img__presentation--shadow" src={imgMariage} alt="prestation mariage" loading="lazy"></img>
            <div className="fs-6 p-5 p-sm-3 text-center content__prestation--width">
                <p className="mb-sm-5 mt-sm-5">
                Votre mariage est l'un des jours les plus <strong>précieux</strong> de votre vie, et <strong>chaque moment compte</strong>. 
                Je suis là pour faire en sorte que vous puissiez <strong>toujours vous rappeler</strong> de chaque instant magique, 
                chaque sourire, chaque émotion, de l'échange des vœux à votre première danse en tant que couple marié.<br/>
                <br/>
                Les différentes offres sont là pour vous aider à choisir ce qui correspond le mieux à vos besoins et à votre 
                budget, mais n’hésitez pas à m’écrire si vous avez besoin d’un <strong>devis personnalisé</strong>.
                </p>
            </div>
            <div className="container d-flex flex-column align-items-center">
                <h3 className="row mt-3 mb-3 mt-sm-5 fs-1">Mes formules</h3>
                <div className="row d-flex justify-content-center m-sm-5 ms-sm-5 me-sm-5 offer__prestation--width">
                    <div className="col-12 col-sm-6 mb-3 p-sm-4">
                        <div className="carte p-4 m-4 p-sm-0 m-sm-0">
                            <div className="mt-3 m-sm-3 d-flex justify-content-center">
                                <img className="img-fluid w-50" src={lovebirds} alt="mariage lovebirds" loading="lazy"></img>
                            </div>
                            <h3 className="m-4 m-sm-5 text-center">Lovebirds</h3>
                            <ul className="ps-5 pe-2 p-sm-0">
                                <li className="pb-3 text-start">Reportage de la cérémonie au vin d’honneur</li>
                                <li className="pb-3 text-start">Séance photo en couple pendant la journée</li>
                                <li className="pb-3 text-start">Galerie en ligne privée pour partager et télécharger les photos</li>
                                <li className="pb-3 text-start">650 €</li>
                            </ul>
                            <div className="d-flex justify-content-center">
                                <button onClick={() => openModal("Mariage - Lovebirds")} className="btn-CTA m-1">Je réserve !</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 mb-3 p-sm-4">
                        <div className="carte p-4 m-4 p-sm-0 m-sm-0">
                            <div className="mt-3 m-sm-3 d-flex justify-content-center">
                                <img className="img-fluid w-50" src={fairytale} alt="mariage fairytale" loading="lazy"></img>
                            </div>
                            <h3 className="m-4 m-sm-5 text-center">Fairytale</h3>
                            <ul className="ps-5 pe-2 p-sm-0">
                                <li className="pb-3 text-start">Reportage des préparatifs jusqu’au vin d’honneur</li>
                                <li className="pb-3 text-start">Séance photo en couple pendant la journée</li>
                                <li className="pb-3 text-start">Galerie en ligne privée pour partager et télécharger les photos</li>
                                <li className="pb-3 text-start">950 €</li>
                            </ul>
                            <div className="d-flex justify-content-center">
                                <button onClick={() => openModal("Mariage - Fairytale")} className="btn-CTA m-1">Je réserve !</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 mb-3 p-2 p-sm-4">
                        <div className="carte p-4 m-4 p-sm-0 m-sm-0">
                            <div className="mt-3 m-sm-3 d-flex justify-content-center">
                                <img className="img-fluid w-50" src={infinity} alt="mariage Infinity" loading="lazy"></img>
                            </div>
                            <h3 className="m-4 m-sm-5 text-center">Infinity</h3>
                            <ul className="ps-5 pe-2 p-sm-0">
                                <li className="pb-3 text-start">Séance photo pré-mariage</li>
                                <li className="pb-3 text-start">Reportage des préparatifs jusqu’au gâteau</li>
                                <li className="pb-3 text-start">Séance photo en couple pendant la journée</li>
                                <li className="pb-3 text-start">Clé USB personnalisée</li>
                                <li className="pb-3 text-start">1400 €</li>
                            </ul>
                            <div className="d-flex justify-content-center">
                                <button onClick={() => openModal("Mariage - Infinity")} className="btn-CTA m-1">Je réserve !</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={modalState.isOpen} onClose={closeModal}>
                    <Form object={modalState.offer} readonly={{ readOnly: true }}/>
                </Modal>
            </div>
        </div>
    );
}

export default Mariage;
