import { React, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

function Connexion() {

    // GET TOKEN 
    const urlToken = "http://www.mariepopulairephotographie.fr/backend/tokenCsrf.php";
    const [csrfToken, setCsrfToken] = useState('');
    useEffect(() => {
        async function getToken() {
            const response = await axios.get(urlToken);
            setCsrfToken(response.data.csrfToken);
            }
    
        getToken();
    }, []);
    
    // HANDLER FORM CONNEXION
    const [connexionData, setConnexionData] = useState({
        token : csrfToken,
        email : '',
        pwd : '',
    })

    // EVENT LISTENER INPUT CHANGE 
    const inputChange = (e) => { 
        const {name, value} = e.target; // e.target.name -> nom de mon input | e.target.value -> valeur de mon input
        setConnexionData(lastState => ({
            ...lastState, // conserver les autres valeurs de connexionData
            [name]: value, // nom de input mod = modification en cours
        }));
    };

    // HANDLER FORM SEND
    const [responseMessage, setResponseMessage] = useState(null);
    const url = "http://www.mariepopulairephotographie.fr/backend/connexion.php"; // cible de traitement ou localhost pour développement
    const sendFormConnexion = async (e) => {
        try { // envoi des informations de connexion
            const response = await axios.post(url, JSON.stringify(connexionData), {
                headers: {
                    'Content-Type': 'application/json' // format JSON
                }
            })
            setResponseMessage(response.data); // renvoi erreur connexion identifiants
        } catch { // capture connexion serveur
            const errorConnexionServeur = (
                <p>Oops, une erreur s'est produite, je n'ai pas pu te connecter !</p>
            )
            setResponseMessage(errorConnexionServeur);
        };
    }


    // FORMULAIRE CONNEXION
    return (
        <div className="row d-flex justify-content-center mt-5 pt-5 mb-5 pb-5 m-0">
            <div className="col-1 col-sm-2 col-md-3 col-lg-3 col-xl-4 col-xxl-4"></div>
            <form className="col-10 col-sm-8 col-md-6 col-lg-6 col-xl-4 col-xxl-4 card p-3 p-xs-4 p-md-5" onSubmit={sendFormConnexion}>
                <legend className="text-center">Espace de connexion</legend>
                <input type="hidden" name="csrfToken" value={connexionData.token}/>
                <div className="d-flex flex-column m-1">
                    <label className="" htmlFor="email" >Email : </label>
                    <input className="rounded ps-1" id="email" name="email" value={connexionData.email} type="email" onChange={inputChange} required></input>
                </div>
                <div className="d-flex flex-column m-1">
                    <label className="" htmlFor="pwd" >Mot de passe : </label>
                    <input className="rounded ps-1" name="pwd" id="pwd" value={connexionData.pwd} type="password" onChange={inputChange} required></input>
                </div>
                <p className="text-danger">{responseMessage}</p> {/* Retour si erreur d'identifiants */}
                <p className="">Pas encore inscrit ? <Link to="/inscription">Cliquez ici !</Link></p>
                <div className="d-flex justify-content-center m-4">
                    <button className="btn-CTA" type="submit">Envoyer</button>
                </div>
            </form>
            <div className="col-1 col-sm-2 col-md-3 col-lg-3 col-xl-4 col-xxl-4"></div>
        </div>
    )
};

export default Connexion;