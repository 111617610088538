import React from 'react';

function LegalNotice() {
    return (
        <div class="modal" id="LegalNotice" tabindex="-1" aria-labelledby="LegalNotice" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header p-3 d-flex justify-content-center position-relative">
                        <h5 className="modal-title position-absolute top-50 start-50 translate-middle" id="mentionsLegales">Mentions légales</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-start footer__LegalNotice--fontfamily">
                        <h6>Article 1 - Définitions</h6>
                            <ul>
                                <li><strong>Cookie</strong> : un cookie est un traceur permettant d’analyser le comportement des internautes, comme leurs navigations, leurs habitudes de consommation, leurs déplacements, etc.</li>
                                <li><strong>Client</strong> : personne morale ou physique qui confie ses intérêts à un professionnel en initiant un acte d’achat.</li>
                                <li><strong>Utilisateur</strong> : tout professionnel, personne morale ou personne physique capable au sens des articles 1123 et suivants du Code civil, qui visite le Site objet des présentes conditions générales.</li>
                                <li><strong>Contenu</strong> : toutes photos, textes, articles, documents présents sur le site.</li>
                                <li><strong>Informations personnelles</strong> : « Les informations qui permettent, sous quelque forme que ce soit, directement ou non, l'identification des personnes physiques auxquelles elles s'appliquent » (article 4 de la loi n° 78-17 du 6 janvier 1978).</li>
                                <li><strong>Données personnelles</strong> : « Toute information se rapportant à une personne physique identifiée ou identifiable (ci-après dénommée « personne concernée ») ; est réputée être une « personne physique identifiable » une personne physique qui peut être identifiée, directement ou indirectement, notamment par référence à un identifiant, tel qu'un nom, un numéro d'identification, des données de localisation, un identifiant en ligne, ou à un ou plusieurs éléments spécifiques propres à son identité physique, physiologique, génétique, psychique, économique, culturelle ou sociale » (RGPD : n° 2016-679).</li>
                                <li><strong>Site</strong> : défini par https://www.mariepopulairephotographie.fr.</li>
                            </ul>
                        <h6>Article 2 - Identités</h6>
                            <p className="mb-0">En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, les Utilisateurs et Client du Site se voient présenter les identités des différents prestataires dans la réalisation et le suivi : </p>
                            <ul>
                                <li>Propriétaire : Entreprise Individuelle Marie Populaire Photographie – 4 rue de l'église 80500 BOUILLANCOURT-LA-BATAILLE</li>
                                <li>Responsable publication : Marie Populaire – mariepopulaire.photographie@laposte.net</li>
                                <li>Webmaster : DIGITAL LOCAL CRAFT – anthony.statek@gmail.com</li>
                                <li>Hébergeur : Hostinger International Ltd – 61 Rue Lordou Vironos 6023 Larnaca, CHYPRE </li>
                                <li>Délégué à la protection des données : DIGITAL LOCAL CRAFT – anthony.statek@gmail.com</li>
                            </ul>
                        <h6>Article 3 - Gestion des données personnelles</h6>
                            <p>Nous rappelons au Client et Utilisateur les réglementations concernant la communication marketing, la loi du 21 Juin 2014 pour la confiance dans l’Economie Numérique, la Loi Informatique et Liberté du 06 août 2004 ainsi que du RGPD (n° 2016-679).</p>
                            <p>Le responsable du traitement des données s’engage à respecter le cadre des dispositions légales en vigueur.</p>
                            <p>Conformément à la loi n°78-17 du 6 janvier relative à l’informatique, aux fichiers et aux libertés, la collecte des données s’effectue dans le respect de la vie privée.</p>
                            <p>Toutes données personnelles collectées sont conservées pour une durée de 12 mois. Elles sont utilisées dans la gestion des prestations et ne sont ni partagées ni vendues à des tiers. Tout refus de collecte des données personnelles entraîne l’impossibilité d’accéder aux prestations vendus sur le Site.</p>
                            <p>Pour toute demande de modification, rectification, suppression d’une ou de l’ensemble des Données personnelles, contacter le responsable du traitement des données (article 1 - DPO) conformément aux articles 17 et 21 du RGPD (n° 2016-679).</p>
                            <p>L’utilisateur garde le droit de déposer une plainte auprès de la CNIL.</p>
                        <h6>Article 4 - Gestion des cookies</h6>
                            <p>Lors des visites sur le site, l’installation automatique d’un cookie sur le logiciel de navigation de l’Utilisateur peut survenir. Certains cookies sont soumis aux consentements des Utilisateurs et d’autres ne suivant pas l’activité des Utilisateurs ne nécessitent pas ce consentement.</p>
                            <p>Cette technologie a pour finalité d’évaluer les réponses des Utilisateurs sur le Site et l’efficacité de ses actions (par exemple, le nombre de fois où une page est ouverte et les informations consultées) pour améliorer l’expérience des Utilisateurs sur le Site.</p>
                            <p>L’emploi occasionnel d’un partenaire spécialiste d’analyses Web est susceptible de se trouver (et donc de stocker les informations correspondantes, y compris l’adresse IP de l’Utilisateur) dans un pays étranger. Ces cookies sont soumis aux consentements des Utilisateurs.</p>
                        <h6>Article 5 - Sécurité des données</h6>
                            <p>Pour assurer la sécurité et la confidentialité des Données Personnelles, des réseaux protégés par des dispositifs standards tels que par pare-feu, la pseudonymisation, le cryptage et mot de passe sont utilisés.</p>
                            <p>Quels que soient les efforts fournis, aucune méthode de transmission sur Internet et aucune méthode de stockage électronique n'est complètement sûre.</p>
                        <h5 className="text-center mt-5 pt-5 p-2">Conditions Générales d'Utilisation</h5>
                            <p>L’utilisation du site implique de facto l’acceptation pleine et entière des conditions générales d’utilisation ci-après décrites. Concernant la description des services fournis.</p>
                            <p>Le Site et Marie Populaire Photographie proposent des services de photographie de mariage, lifestyle, entreprise et professionnel sans limitation de secteur, d’activités ou de projet.</p>
                            <p>Toutefois, il ne pourra être tenu responsable des oublis, des inexactitudes et des carences dans la mise à jour, qu’elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations.</p>
                            <p>Toutes les informations indiquées sur le Site sont données à titre indicatif et sont susceptibles d’évoluer. Les renseignements présents sur le Site sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne.</p>
                            <h6>Propriété intellectuelle</h6>
                                <p>Le Site et tous les éléments s’y trouvant sont une œuvre de l’esprit de DIGITAL LOCAL CRAFT ou Marie Populaire Photographie sauf mentions contraires.</p>
                                <p>L’Utilisateur ou Client ne peut en aucune manière s’approprier, réutiliser, céder ou exploiter, en tout ou partie, le Contenu présent sur le Site en son nom ou celui de quelqu’un d’autre.</p>
                                <p>Tous écrits, documents, images, vidéos et photographies présents sur le Site sont l’exclusive propriété intellectuelle de Marie Populaire Photographie et ne peuvent être utilisés, céder ou exploiter, en tout ou partie, sans l’accord préalable de Marie Populaire Photographie.</p>
                                <p>Tous les autres éléments, designs, graphismes et le Site en lui-même sont l’exclusive propriété intellectuelle de Digital Local Craft et ne peuvent être utilisés, céder ou exploiter, en tout ou partie, sans l’accord préalable de Digital Local Craft.</p>
                                <p>Toute utilisation non autorisée de Digital Local Craft ou Marie Populaire Photographie, du Site ou de l’un des éléments respectivement suscités sera constitutive d’une contrefaçon et poursuivie conformément aux dispositions des articles L335-2 et suivants du Code de la Propriété Intellectuelle.</p>
                            <h6>Limitation de responsabilité</h6>
                                <p>Le Site utilisant la technologie JavaScript, DIGITAL LOCAL CRAFT ou Marie Populaire Photographie ne pourront être tenu responsable de dommages matériels liés à l’utilisation du Site. De plus, l’utilisateur s’engage à accéder au Site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis à jour.</p>
                                <p>Le Site est hébergé chez un prestataire sur le territoire de l’Union Européenne conformément aux dispositions du Règlement Général sur la Protection des Données (RGPD : n° 2016-679). L’hébergeur assure la continuité de son service 24 Heures sur 24, tous les jours de l’année. Il se réserve néanmoins la possibilité d’interrompre le service d’hébergement pour les durées les plus courtes possibles notamment à des fins de maintenance, d’amélioration de ses infrastructures, de défaillance de ses infrastructures ou si les Prestations et Services génèrent un trafic réputé anormal.</p>
                                <p>Le Site, DIGITAL LOCAL CRAFT et l’hébergeur ne pourront être tenus responsables en cas de dysfonctionnement du réseau Internet, des lignes téléphoniques ou du matériel informatique et de téléphonie lié notamment à l’encombrement du réseau empêchant l’accès au serveur.</p>
                                <p>Le Site et/ou DIGITAL LOCAL CRAFT ne pourront être tenus responsable des dommages directs et indirects causés au matériel de l’utilisateur, lors de l’accès au site internet https://mariepopulairephotographie.fr, et résultant soit de l’utilisation d’un matériel ne répondant pas aux spécifications indiquées précédemment, soit de l’apparition d’un bug ou d’une incompatibilité.</p>
                                <p>Le site et/ou DIGITAL LOCAL CRAFT ne pourront également être tenus responsable des dommages indirects (à l’exemple d’une perte de marché) consécutifs à l’utilisation du Site.</p>
                            <h6>Exclusion de responsabilité</h6>
                                <p>DIGITAL LOCAL CRAFT agit en tant que concepteur/éditeur du Site et ne peut être responsable du Contenu du Site.</p>
                                <p>Le Site utilise des liens externes renvoyant vers des sites et des ressources sur d’autres domaines. DIGITAL LOCAL CRAFT et Marie Populaire Photographie ne peuvent être tenus de la fiabilité, du fonctionnement ou du Contenu en lui-même sur ses autres pages web et ressources.</p>
                            <h6>Droit applicable et juridiction compétente</h6>
                                <p>Le Site est soumis au droit français. La loi imposant une tentative de résolution du litige amiable, la médiation via https://www.mediationpicardie.fr/ est à privilégier. En cas d’absence de résolution à l’amiable des cas de litige entre les parties, il est fait attribution exclusive de juridiction aux tribunaux compétents d’AMIENS, juridiction la plus proche du siège de l’entreprise.</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="rounded bg-black text-light p-1 ps-2 pe-2" data-bs-dismiss="modal">Fermer</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LegalNotice;