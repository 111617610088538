import { React, useState } from 'react';


// FILE
import Contact from '../components/Contact.js';
import Modal from "../components/Modal.js";
import Form from "../components/Form.js";

// IMAGE
import imgLifestyle from '../assets/imgPrestation/lifestyle.webp';
import decouverte from '../assets/imgPrestation/lifestyle/Decouverte.webp';
import famille from '../assets/imgPrestation/lifestyle/enFamille.webp';


const Lifestyle = () => {
    const [modalState, setModalState] = useState({ isOpen: false, offer: null });

    const openModal = (offer) => {
        setModalState({isOpen: true, offer});
    }
    
    const closeModal = () => {
        setModalState({isOpen: false, offer: null});
    }

    return (
        <div className="d-flex align-items-center flex-column">
            <Contact />
            <h2 className="m-5 m-sm-3 fs-secondary">Lifestyle</h2>
            <img className="w-75 m-sm-3 content__prestation--width img__presentation--shadow" src={imgLifestyle} alt="prestation lifestyle" loading="lazy"></img>
            <div className="fs-6 p-5 p-sm-3 text-center content__prestation--width">
                <p className="mb-sm-5 mt-sm-5">
                Créez des souvenirs <strong>authentiques</strong> avec un shooting lifestyle. 
                Partagez des moments <strong>précieux</strong> en couple ou en famille, 
                et capturez un <strong>instant de vie</strong> que vous <strong>n’oublierez jamais</strong>.
                </p>
            </div>
            <div className="container d-flex flex-column align-items-center">
                <h3 className="row mt-3 mb-3 mt-sm-5 fs-1">Mes formules</h3>
                <div className="row d-flex justify-content-center m-sm-5 ms-sm-5 me-sm-5 offer__prestation--width">
                    <div className="col-12 col-sm-6 mb-3 p-sm-4">
                        <div className="carte p-4 m-4 p-sm-0 m-sm-0">
                            <div className="mt-3 m-sm-3 d-flex justify-content-center">
                                <img className="img-fluid w-75" src={decouverte} alt="lifestyle decouverte de soi" loading="lazy"></img>
                            </div>
                            <h3 className="m-4 m-sm-5 text-center">Découverte de soi</h3>
                            <ul className="ps-5 pe-2 p-sm-0">
                                <li className="pb-3 text-start">Session de prise de vue lifestyle d'une demi heure dans un lieu intérieur ou extérieur de votre choix</li>
                                <li className="pb-3 text-start">Conseils de pose et de mise en scène pour des photos naturelles et authentiques</li>
                                <li className="pb-3 text-start">Sélection et retouche des 12 meilleures photos</li>
                                <li className="pb-3 text-start">Galerie en ligne privée pour visionner et télécharger les photos</li>
                                <li className="pb-3 text-start">120€</li>
                            </ul>
                            <div className="d-flex justify-content-center">
                                <button onClick={() => openModal("Lifestyle - Découverte de soi")} className="btn-CTA m-1">Je réserve !</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 mb-3 p-sm-4">
                        <div className="carte p-4 m-4 p-sm-0 m-sm-0">
                            <div className="mt-3 m-sm-3 d-flex justify-content-center">
                                <img className="img-fluid w-75" src={famille} alt="lifestyle En famille" loading="lazy"></img>
                            </div>
                            <h3 className="m-4 m-sm-5 text-center">En famille</h3>
                            <ul className="ps-5 pe-2 p-sm-0">
                                <li className="pb-3 text-start">Session de prise de vue lifestyle d’une heure pour capturer des moments précieux en famille, dans un lieu intérieur ou extérieur de votre choix</li>
                                <li className="pb-3 text-start">Conseils de pose et de mise en scène pour des photos naturelles et authentiques</li>
                                <li className="pb-3 text-start">Sélection et retouche des 25 meilleures photos</li>
                                <li className="pb-3 text-start">Galerie en ligne privée pour visionner et télécharger les photos</li>
                                <li className="pb-3 text-start">230€</li>
                            </ul>
                            <div className="d-flex justify-content-center">
                                <button onClick={() => openModal("Lifestyle - En famille")} className="btn-CTA m-1">Je réserve !</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={modalState.isOpen} onClose={closeModal}>
                    <Form object={modalState.offer} readonly={{ readOnly: true }}/>
                </Modal>
            </div>
        </div>
    );
}

export default Lifestyle;
