import { React, useState } from 'react';


// FILE
import Contact from '../components/Contact.js';
import Modal from "../components/Modal.js";
import Form from "../components/Form.js";

// IMAGE
import imgIris from '../assets/imgPrestation/iris.webp';
import solo from '../assets/imgPrestation/iris/solo.webp';
import duo from '../assets/imgPrestation/iris/duo.webp';
import trio from '../assets/imgPrestation/iris/trio.webp';
import quatuor from '../assets/imgPrestation/iris/quatuor.webp';
import prix from '../assets/imgPrestation/iris/Prix.jpeg';



const Lifestyle = () => {
    const [modalState, setModalState] = useState({ isOpen: false, offer: null });

    const openModal = (offer) => {
        setModalState({isOpen: true, offer});
    }
    
    const closeModal = () => {
        setModalState({isOpen: false, offer: null});
    }

    return (
        <div className="d-flex align-items-center flex-column">
            <Contact />
            <h2 className="m-5 m-sm-3 fs-secondary">Iris</h2>
            <img className="w-75 m-sm-3 content__prestation--width img__presentation--shadow" src={imgIris} alt="prestation lifestyle" loading="lazy"></img>
            <div className="fs-6 p-5 p-sm-3 text-center content__prestation--width">
                <p className="mb-sm-5 mt-sm-5 fs-2">Plus qu’une photo, découvrez l’oeuvre d’art qui réside dans votre regard.</p>
                <p className="mb-sm-5 mt-sm-5">
                À travers cette <strong>expérience originale</strong>, vous allez capter la beauté de votre <strong>regard</strong> au plus près.
                Seul(e), en amoureux ou en famille, le <strong>résultat sera unique</strong> et les <strong>souvenirs authentiques</strong>.
                </p>
                <p className="mb-sm-5 mt-sm-5">
                Grâce à la <strong>formation officielle</strong> donnée par Romain Bebon, je vous assure une prise de vue <strong>professionnelle</strong> et sans aucun risque pour la santé.
                </p>
                <p className="mb-sm-5 mt-sm-5">
                Après une prise de vue de votre iris, je travaille l’image de manière à pouvoir vous offrir une représentation fidèle de votre regard.
                Je vous propose ensuite le choix entre plusieurs supports qui sublimeront tous vos iris.
                Toutes les formules de base contiennent le format numérique au format web.
                </p>
            </div>
            <div className="container d-flex flex-column align-items-center">
                <h3 className="row mt-3 mb-3 mt-sm-5 fs-1">Mes formules</h3>
                <div className="row d-flex justify-content-center m-sm-5 ms-sm-5 me-sm-5 offer__prestation--width">
                    {/* for 1 person */}
                    <div className="col-12 col-sm-6 mb-3 p-sm-4">
                        <div className="carte p-4 m-4 p-sm-0 m-sm-0">
                            <div className="mt-3 m-sm-3 d-flex justify-content-center">
                                <img className="img-fluid w-100 img__presentation--shadow imgPrestationPrincipalPage" src={solo} alt="photographie d'un iris" loading="lazy"></img>
                            </div>
                            <h3 className="m-4 m-sm-5 text-center">Iris solo</h3>
                            <ul className="ps-5 pe-2 p-sm-0">
                                <li className="pb-3 text-start">Pour 1 personne</li>
                                <li className="pb-3 text-start">Prise de vue </li>
                                <li className="pb-3 text-start">Conception graphique et post traitement</li>
                                <li className="pb-3 text-start">photo numérique web (non imprimable)</li>
                                <li className="pb-3 text-start">65€</li>
                            </ul>
                            <div className="d-flex justify-content-center">
                                <button onClick={() => openModal("Iris - solo")} className="btn-CTA m-1">Je réserve !</button>
                            </div>
                        </div>
                    </div>
                    {/* for 2 persons */}
                    <div className="col-12 col-sm-6 mb-3 p-sm-4">
                        <div className="carte p-4 m-4 p-sm-0 m-sm-0">
                            <div className="mt-3 m-sm-3 d-flex justify-content-center">
                                <img className="img-fluid w-100 img__presentation--shadow imgPrestationPrincipalPage" src={duo} alt="photographie de deux iris" loading="lazy"></img>
                            </div>
                            <h3 className="m-4 m-sm-5 text-center">Iris duo</h3>
                            <ul className="ps-5 pe-2 p-sm-0">
                                <li className="pb-3 text-start">Pour 2 personnes</li>
                                <li className="pb-3 text-start">Prise de vue </li>
                                <li className="pb-3 text-start">Conception graphique et post traitement</li>
                                <li className="pb-3 text-start">photo numérique web (non imprimable)</li>
                                <li className="pb-3 text-start">105€</li>
                            </ul>
                            <div className="d-flex justify-content-center">
                                <button onClick={() => openModal("Iris - duo")} className="btn-CTA m-1">Je réserve !</button>
                            </div>
                        </div>
                    </div>
                    {/* for 3 persons */}
                    <div className="col-12 col-sm-6 mb-3 p-sm-4">
                        <div className="carte p-4 m-4 p-sm-0 m-sm-0">
                            <div className="mt-3 m-sm-3 d-flex justify-content-center">
                                <img className="img-fluid w-100 img__presentation--shadow imgPrestationPrincipalPage decalageSize" src={trio} alt="photographie de trois iris" loading="lazy"></img>
                            </div>
                            <h3 className="m-4 m-sm-5 text-center">Iris trio</h3>
                            <ul className="ps-5 pe-2 p-sm-0">
                                <li className="pb-3 text-start">Pour 3 personnes</li>
                                <li className="pb-3 text-start">Prise de vue </li>
                                <li className="pb-3 text-start">Conception graphique et post traitement</li>
                                <li className="pb-3 text-start">photo numérique web (non imprimable)</li>
                                <li className="pb-3 text-start">145€</li>
                            </ul>
                            <div className="d-flex justify-content-center">
                                <button onClick={() => openModal("Iris - trio")} className="btn-CTA m-1">Je réserve !</button>
                            </div>
                        </div>
                    </div>
                    {/* for 4 persons */}
                    <div className="col-12 col-sm-6 mb-3 p-sm-4">
                        <div className="carte p-4 m-4 p-sm-0 m-sm-0">
                            <div className="mt-3 m-sm-3 d-flex justify-content-center">
                                <img className="img-fluid w-100 img__presentation--shadow imgPrestationPrincipalPage decalageSize" src={quatuor} alt="photographie de quatre iris" loading="lazy"></img>
                            </div>
                            <h3 className="m-4 m-sm-5 text-center">Iris quatuor</h3>
                            <ul className="ps-5 pe-2 p-sm-0">
                                <li className="pb-3 text-start">Pour 4 personnes</li>
                                <li className="pb-3 text-start">Prise de vue </li>
                                <li className="pb-3 text-start">Conception graphique et post traitement</li>
                                <li className="pb-3 text-start">photo numérique web (non imprimable)</li>
                                <li className="pb-3 text-start">195€</li>
                            </ul>
                            <div className="d-flex justify-content-center">
                                <button onClick={() => openModal("Iris - quatuor")} className="btn-CTA m-1">Je réserve !</button>
                            </div>
                        </div>
                    </div>
                    {/* affichage tirables & tableaux */}
                    <div className="col-12 col-sm-6 mb-3 p-sm-4">
                    <div className="mt-3 m-sm-3 d-flex justify-content-center">
                                <img className="img-fluid w-100 img__presentation--shadow imgPrestationPrincipalPage decalageSize" src={prix} alt="photographie de quatre iris" loading="lazy"></img>
                            </div>
                    </div>
                </div>
                <Modal isOpen={modalState.isOpen} onClose={closeModal}>
                    <Form object={modalState.offer} readonly={{ readOnly: true }}/>
                </Modal>
            </div>
        </div>
    );
}

export default Lifestyle;
