import React from 'react';

function TermsOfSales() {
    return (
        <div class="modal" id="TermsOfSales" tabindex="-1" aria-labelledby="TermsOfSales" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content ">
                    <div className="modal-header p-3 d-flex justify-content-center position-relative">
                        <h6 className="modal-title position-absolute top-50 start-50 translate-middle" id="conditionsGeneralesDeVente">Conditions générales de vente</h6>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-start footer__LegalNotice--fontfamily">
                        <h6>Article 1 - Définitions</h6>
                            <ul>
                                <li><strong>CGV</strong> : informe le Client des conditions de vente d'un produit ou d'une prestation de services.</li>
                                <li><strong>Site</strong> : défini par https://www.mariepopulairephotographie.fr.</li>
                                <li><strong>Pénalités de retard</strong> : sanction en cas de non-respect d'obligations. Elle se traduit souvent sous la forme d'intérêts de retard ou de majorations de paiement.</li>
                                <li><strong>Client</strong> : personne morale ou physique qui confie ses intérêts à un professionnel en initiant un acte d’achat.</li>
                                <li><strong>Les deux parties</strong> : fait référence au Client (professionnel ou particulier) et à Marie Populaire Photographie.</li>
                                <li><strong>Circonstances particulières </strong>: maladies, accidents, défaillances de matériels.</li>
                                <li><strong>Cas de force majeure </strong>: événement qui remplit l'ensemble des 3 caractéristiques suivantes :</li>
                                <ul>
                                    <li>Ne peut pas être prévu (imprévisible),</li>
                                    <li>Ne peut pas être surmonté (irrésistible),</li>
                                    <li>Fait extérieur échappant au contrôle de la personne concernée.</li>
                                </ul>
                            </ul>
                        <h6>Article 2 - Communication des CGV</h6>
                            <p>Conformément aux articles L.111-1 et suivants du Code de la consommation et à la loi Hamon du 17 mars 2014, les présente Conditions Générales de Vente (CGV) sont fournies au Client particulier avec le devis et sur demande au Client professionnel. Ce dernier doit être signé, soit manuellement sur papier, soit par signature électronique sécurisée via Smallpdf.com.</p>
                            <p>Pour les commandes effectuées directement sur le site, l'acceptation des CGV se fait par le biais d'une case à cocher avant le processus de paiement. Les CGV sont également incluses en seconde partie de la facture.</p>
                        <h6>Article 3 - Objet</h6>
                            <p>Les présentes Conditions Générales de Vente (CGV) établissent un contrat légal entre le Client et Marie Populaire Photographie. Elles s'appliquent à toutes les commandes réalisées, que ce soit directement sur le Site ou via un devis signé.</p>
                            <p>Le Client déclare être majeur et consentir librement et volontairement aux prises de vue réalisées par Marie Populaire Photographie. Pour les offres impliquant des mineurs, notamment dans le cadre des sessions mariage et lifestyle, le Client certifie être le responsable légal des mineurs concernés et autorise leur participation aux prises de vue. Concernant les offres de mariage, le Client s'engage également à obtenir les autorisations nécessaires de la part de tous les représentants légaux des enfants mineurs susceptibles d'apparaître dans les photographies lors de l'événement.</p>
                        <h6>Article 4 - Réservation d’une prestation de photographie</h6>
                            <p className="mb-0">Les prestations de photographies peuvent être réservées :</p>
                            <ul>
                                <li>Par mail à mariepopulaire.photographie@laposte.net,</li>
                                <li>Par téléphone au 06 33 68 03 49,</li>
                                <li>Via le compte Instagram (Marie Populaire Photographie),</li>
                                <li>Via le compte Facebook (Marie Populaire Photographie),</li>
                                <li>Via le formulaire de contact présent sur le Site.</li>
                            </ul>
                        <h6>Article 5 - Déroulement d'une prestation</h6>
                            <p>Le Client s'engage à fournir toutes les informations nécessaires à l'exécution de la prestation en amont. Ces informations doivent inclure l'heure exacte du rendez-vous (en tenant compte d'éventuels retards dus à la circulation), l'adresse précise du lieu de la prestation ou, en l'absence d'une adresse formelle, les coordonnées GPS, ainsi que les coordonnées de la personne à contacter sur place si celle-ci n'est pas la personne ayant signé le devis.</p>
                            <p>Le Client doit également s'assurer que Marie Populaire Photographie puisse réaliser sa prestation sans aucune gêne causée par des tiers. Seules les personnes directement impliquées dans la séance sont autorisées à être présentes.</p>
                            <p>Il est impératif que l'heure convenue pour la prestation soit respectée. En cas de retard de plus de 20 minutes, le temps excédentaire sera déduit de la durée totale du shooting prévue dans le devis.</p>
                        <h6>Article 6 - Exécution de la prestation de services</h6>
                            <p>Le contrat, établi par la signature du devis, engage une durée qui s'étend de la date de signature du devis jusqu'à la livraison de la galerie de photos au Client. La livraison de la galerie interviendra au plus tard 90 jours après la réalisation de la prestation, sauf en cas de force majeure ou de circonstances particulières.</p>
                        <h6>Article 7 - Tarifs</h6>
                            <p className="mb-0">Les prix de vente, affichés hors taxes (TVA non-applicable, conformément à l'article 293 B du CGI), sont disponibles sur le Site dans la section « Mes prestations ». Ces tarifs sont susceptibles de subir des modifications. En cas de modification des prix après l'établissement d'un devis non signé, Marie Populaire Photographie s'engage à :</p>
                            <ul>
                                <li>Soit transmettre un nouveau devis avec les prix mis à jour,</li>
                                <li>Soit honorer les prix indiqués sur le devis initial avant la modification des tarifs.</li>
                            </ul>
                            <p>Il est possible d'appliquer des prix différents de ceux indiqués sur le Site, suite à un accord mutuel formalisé par un devis signé entre les deux parties pour un projet particulier.</p>
                        <h6>Article 8 - Conditions de règlement</h6>
                            <p>Le Client peut effectuer son règlement par virement SEPA, prélèvement automatique ou en espèces.</p>
                            <p>Le paiement peut être réalisé en une seule fois ou échelonné en plusieurs versements pour toute commande supérieure à 500 €.</p>
                            <p>La totalité du paiement doit être réglée au plus tard 10 jours calendaires avant la date de la prestation, telle que définie et signée sur le devis, à l'exception des abonnements photographiques.</p>
                            <p>Pour les contrats d’abonnements photographiques, le paiement est dû dans les 7 jours suivant la réception de la facture, chaque facture étant émise selon la périodicité convenue avec le Client.</p>
                        <h6>Article 9 - Pénalités de retard</h6>
                            <p>En cas de retard de paiement, c'est-à-dire si la totalité ou une partie de la somme due n'est pas réglée 9 jours calendaires avant la date de la prestation, sauf mention contraire sur le devis et la facture, des pénalités de retard seront appliquées.</p> 
                            <p>Ces pénalités sont applicables au jour suivant la date de règlement inscrite sur la facture et comprennent une indemnité forfaitaire ainsi que des pénalités de retard fixées légalement à l’article L.441-6 du Code de commerce.</p>
                            <p>L’indemnité forfaitaire est fixée à 40 €. Si les frais de recouvrement exposés sont supérieurs au montant de l’indemnité forfaitaire, une indemnisation supplémentaire du montant des frais de recouvrement sera demandée au débiteur sur présentation d’un justificatif (facture des frais de recouvrement).</p>
                            <p>Les taux d'intérêts des pénalités de retard pour le 1er trimestre 2024 sont fixés à 15,21 % pour les transactions entre professionnels et à 18,01 % pour celles impliquant des particuliers.</p>
                        <h6>Article 10 - Garantie légale de conformité</h6>
                            <p>Les prestations de photographie fournies par Marie Populaire Photographie sont garantis conformes aux termes du contrat, tels que définis dans l'offre choisie et/ou stipulées dans le devis. Conformément aux articles L217-4 à L217-14 du Code de la consommation, nos prestations doivent correspondre à la description et aux spécifications convenues avec le Client, que ce soit lors de la commande sur le Site ou lors de la signature du devis.</p>
                            <p>En cas de non-conformité des photographies livrées par rapport à ce qui a été explicitement convenu, notamment en termes de quantité, de qualité, de dimensions ou de délais de livraison, le Client a le droit de demander une mise en conformité des photos sans aucun coût supplémentaire représentant 20 % du nombre de photographies rendus.</p>
                            <p className="mb-0">Exercer la garantie légale de conformité :</p>
                            <ul>
                                <li>Pour faire valoir cette garantie, le Client doit contacter Marie Populaire Photographie à l'adresse suivante : mariepopulaire.photographie@laposte.net, immédiatement après la découverte de la non-conformité, en fournissant une description détaillée du défaut. Il est recommandé de fournir des preuves pour appuyer la réclamation. Cette garantie est valable pendant deux ans à compter de la livraison des photographies.</li>
                            </ul>
                        <h6>Article 11 - Garnatie légale des vices cachés</h6>
                            <p>Conformément à l'article 1641 du Code civil, Marie Populaire Photographie offre une garantie contre les vices cachés qui pourraient affecter les photographies livrées. Un vice caché se définit comme un défaut non-apparent au moment de la livraison qui rend les photos inutilisables ou diminue significativement leur utilisation à un point tel que le Client ne les auraient pas acquises, ou les auraient acquises à un prix moindre, s'il avait été informé de ce défaut.</p>
                            <p className="mb-0">En ce qui concerne Marie Populaire Photographie et ses prestations, il s’agit de :</p>
                            <ul>
                                <li>Un ou plusieurs fichiers corrompus (ne pouvant être ouvert),</li>
                                <li>Un support numérique type clé USB corrompu.</li>
                            </ul>
                            <p>En cas de découverte d'un vice caché, Marie Populaire Photographie s'engage à remplacer les fichiers corrompus sur la galerie privée en ligne et à remplacer tout support numérique défectueux après vérification, sans frais supplémentaires pour le Client.</p>
                            <p className="mb-0">Exercer la garantie légale des vices cachés :</p>
                            <ul>
                                <li>Pour bénéficier de cette garantie, le Client doit contacter Marie Populaire Photographie à l'adresse suivante : mariepopulaire.photographie@laposte.net, immédiatement après avoir constaté le vice, en fournissant une description détaillée du défaut. Le Client est encouragé à fournir des preuves à l'appui de la réclamation. Cette garantie est valable pendant deux ans à compter de la découverte du vice.</li>
                            </ul>
                        <h6>Article 12 - Droit de rétractation Client</h6>
                            <p>Le Client dispose d’un délai de rétractation après signature d’un devis ou passation d’une commande sur le Site. Ce délai est fixé à 14 jours.</p>
                            <p>Toute rétractation dûment remplie et transmise dans ce délai fera l’objet d'un remboursement du montant de la commande ou du devis. Passé ce délai, aucun remboursement ne sera effectué.</p>
                            <p>Si, après accord préalable exprès du Client et renoncement exprès à son droit de rétractation, la prestation est réalisée en tout ou partie avant expiration du délai de rétraction, aucun remboursement ne pourra être effectué.</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="rounded bg-black text-light p-1 ps-2 pe-2" data-bs-dismiss="modal">Fermer</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsOfSales;