import React from 'react';

// FILE
import Contact from '../components/Contact.js';

// IMG
import MarieFirst from '../assets/imgWhoIam/marie1.jpg';
import MarieSecond from '../assets/imgWhoIam/marie2.jpg';
import MarieThird from '../assets/imgWhoIam/marie3.jpg';

// CSS
import '../assets/css/Presentation.css';


const WhoIam = () => {
    return (
        <div>
            <Contact />
            <h2 className="text-center mt-5 mb-5">Bienvenue dans mon humble espace photographique</h2>

            <div className="container pt-0 pt-sm-5">
{/* FIRST ROW */}

                <div className="row mt-5 mb-5">
                    <div className="col-3 col-sm-2"></div> {/* decalage pour ne pas être derrière le menu fixe a gauche */} 
                    <div className="col-6 col-sm-3">
                        <img className="img-fluid" src={MarieFirst} alt="photographe dans l'église" loading="lazy"></img>
                    </div>
                    <div className="col-3 d-sm-none"></div>
                    <div className="col-2 d-sm-none"></div>
                    <div className="col-8 col-sm-5 d-flex align-items-center pt-3 pt-sm-0">
                            <p className="presentation__text--style lh-lg ps-sm-5"> 
                                Je suis Marie, une jeune passionnée âgée de 24 ans, armée d'un appareil photo et d'une 
                                sensibilité à fleur de peau. Pour moi, la photographie n'est pas seulement un art, c'est une manière de 
                                capturer les instants fugaces de la vie, de les immortaliser avant qu'ils ne s'échappent dans l'oubli.
                            </p>
                    </div>
                    <div className="col-2 col-sm-1"></div> {/* decalage pour ne pas être derrière l'icone contact */}
                </div>

{/* SECOND ROW */}

                <div className="row mt-5 mb-5">
                    <div className="col-2"></div> {/* decalage pour ne pas être derrière le menu fixe a gauche */} 
                    <div className="col-8 col-sm-5 d-flex align-items-center pt-3 pt-sm-0">
                        <p className="presentation__text--style lh-lg pe-sm-5">
                            Je suis habitée par la peur de l'oubli, celle qui me pousse à figer chaque sourire, chaque regard, chaque 
                            émotion dans mes photographies. Chaque image est un voyage dans mes souvenirs, une fenêtre ouverte sur des 
                            moments qui ont marqué mon chemin.
                        </p>
                    </div>
                    <div className="col-2 d-sm-none"></div>
                    <div className="col-3 d-sm-none"></div> 
                    <div className="col-6 col-sm-3">
                        <img className="img-fluid" src={MarieSecond} alt="photographe avec un appareil" loading="lazy"></img>
                    </div>
                    <div className="col-2"></div> {/* decalage pour ne pas être derrière l'icone contact */}
                </div>

{/* THIRD ROW */}

                <div className="row mt-5 mb-5 pt-5 pt-sm-0">
                    <div className="col-3 col-sm-2"></div> {/* decalage pour ne pas être derrière le menu fixe a gauche */} 
                    <div className="col-6 col-sm-3">
                        <img className="img-fluid" src={MarieThird} alt="phtooraphe sur un pont" loading="lazy"></img>
                    </div>
                    <div className="col-3 d-sm-none"></div>
                    <div className="col-2 d-sm-none"></div>
                    <div className="col-8 col-sm-5 d-flex align-items-center pt-3 pt-sm-0">
                        <p className="presentation__text--style lh-lg ps-sm-5">
                            Je suis une âme curieuse et ultrasensible, avide de capturer la beauté simple et authentique qui nous entoure. 
                            Je vous invite à parcourir mon univers visuel, à vous plonger dans mes souvenirs figés, à partager avec moi 
                            cette passion qui m'anime.
                        </p>
                    </div>
                    <div className="col-2 col-sm-1"></div> {/* decalage pour ne pas être derrière l'icone contact */}
                </div>
                <div className="row mt-5 mb-5">
                    <h4 className="col-11 text-end">Merci de vous arrêter un instant dans mon monde photographique</h4>
                    <div className="col-1"></div> {/* decalage pour ne pas être derrière l'icone contact */}
                </div>
            </div>
        </div>
    );
}

export default WhoIam;